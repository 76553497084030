import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from "@/util/api";
import { catApi } from "@/util/logging";
import Datum from "@/util/datum";

const fmt = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});
const fmw = new Intl.NumberFormat("de-DE", { style: "percent" });

export class ProduktModel extends BaseType {
  @Expose() uid = "";
  @Expose() url = "";
  @Expose() kategorie = "";
  @Expose() warengruppe = "";
  @Expose() titel = "";
  @Expose() untertitel = "";
  @Expose() preis = 0.0;
  @Expose() ek = null;
  @Expose() mwst = null;
  @Expose() zusatztext = null as null | string;
  @Expose() datum = null as null | string;
  @Expose() EAN = null as null | string;
  @Expose() EANliste = null as null | Array<string>;

  public fmtPreis(): string {
    return fmt.format(this.preis);
  }

  public fmtDatum(): string {
    return Datum.p5sdDD(this.datum, "-/-");
  }

  public fmtMwSt(): string {
    if (this.mwst === null || this.mwst === undefined) return "";

    return fmw.format(Number(this.mwst) / 100);
  }

  // === === === === === === === === === === === === === ===

  static find = async (
    produkt: string,
    filter?: string
  ): Promise<ProduktModel[]> => {
    catApi.info("querying: " + produkt);
    if (produkt === null || produkt === undefined || produkt == "") {
      catApi.info("  return: [] (because " + produkt + ")");
      return [];
    }

    try {
      const options = { params: {} };
      if (filter !== undefined) {
        options.params = {
          filter: filter,
        };
      }
      const path = `v1/produkte/${api.encodeComponent(produkt)}`;
      const response = await api.get<ProduktModel[]>(path, options);
      catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convertArray(ProduktModel, response.data);

      if (!Array.isArray(ret)) {
        catApi.error("Should have gotten a List, got nothing!", null);
        throw new Error("ProduktModel no list");
      }

      return ret;
      //
    } catch (e) {
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };
}
