























































































































import { catApi, catInput, catNav, catVue } from "@/util/logging";
import { clearTimeout, setTimeout } from "timers";

import { ProduktModel } from "@/models/ProduktModel";
import { Route } from "vue-router";
import Vue from "vue";
import router from "@/router";
import store from "@/store";

import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";

export default Vue.extend({
  name: "Regalkontrolle",
  components: {
    BarcodeButton: () =>
      import(
        /* webpackChunkName: "BarcodeButton" */ "@/components/util/BarcodeButton.vue"
      ),
    BuchAnzeige: () =>
      import(
        /* webpackChunkName: "BuchAnzeige" */ "@/components/regalkontrolle/BuchAnzeige.vue"
      ),
    PresseAnzeige: () =>
      import(
        /* webpackChunkName: "PresseAnzeige" */ "@/components/regalkontrolle/PresseAnzeige.vue"
      ),
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.input as any).focus();

    catVue.info(JSON.stringify(this.$route.params));
    this.$nextTick(() => {
      catApi.info("Searching initial produkt ... ?");
      this.search();
    });

    this.lastKey = Date.now();
  },
  data: () => ({
    valid: false,
    base: process.env.VUE_APP_API_ENDPOINT,
    produkt: "",
    // Validation Rules
    produktRules: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v: any) => !!v || "Irgendein Produktschlüssel muss sein",
      //          v => v.length <= 10 || 'Name must be less than 10 characters',
    ],
    produktListe: [] as ProduktModel[],
    selectedProdukt: {} as ProduktModel,
    selectionIndex: -1,
    lastKey: -1,
    timeOut: undefined as ReturnType<typeof setTimeout> | undefined,
    //
    auth: getModule(AuthModule, store),
  }),
  computed: {
    //
    detailComponent(): string {
      catVue.info("detailComponent is called ... " + this.produkt);

      let ret = "";
      if (this.selectedProdukt.kategorie == "Buch") ret = "BuchAnzeige";
      if (this.selectedProdukt.kategorie == "Presse") ret = "PresseAnzeige";

      catVue.info("Returning ... " + ret);
      return ret;
    },
    currentProperties() {
      return { produkt: this.selectedProdukt };
    },
    //
  },
  props: {
    /* intentionally empty */
  },
  methods: {
    //
    submit(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(this.$refs.form as any).validate()) return;

      let update = !(
        "produkt" in this.$route.params &&
        this.produkt == this.$route.params.produkt
      );

      if (update) {
        catVue.info(`Pushing new Route with ${this.produkt}`);
        this.$nextTick(() => {
          router.push({
            name: "Regalkontrolle",
            params: { produkt: this.produkt },
          });
        });
      } else catVue.info(`No new Route (${this.produkt})`);
    },

    keyDown(): void {
      if (undefined !== this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        catVue.info("focus ...");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const input = (this.$refs["input"] as any).$refs.input;
        input.select();
        input.focus();
      }, 999);
    },

    async search() {
      //
      let produkt = "";
      this.selectedProdukt = {} as ProduktModel;
      this.selectionIndex = -1;
      this.produktListe = [];

      if ("produkt" in this.$route.params) {
        produkt = this.$route.params.produkt.trim();
      }

      if (produkt !== this.produkt) {
        catVue.info(`Update this.produkt = (${produkt})`);
        this.produkt = produkt;
      }

      catInput.info("Input: " + produkt);
      if (produkt == "") {
        return;
      }

      try {
        this.$store.commit("changeAppLoadingState", true, { root: true });
        const response = await ProduktModel.find(this.produkt);
        this.$store.commit("changeAppLoadingState", false, { root: true });
        catApi.debug("Output: " + JSON.stringify(response));

        this.produktListe = response;
        if (0 == response.length) {
          store.commit("setLastError", `Nichts gefunden zu "${this.produkt}"`);
        } else {
          this.selectedProdukt = this.produktListe[0];
        }
      } catch (e) {
        catApi.error("RegalKontrolle: " + e, null);
        //               store.commit("setLastError", e);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const input = (this.$refs["input"] as any).$refs.input;
      input.select();
      input.focus();
    },
  },
  watch: {
    selectionIndex(to: number, from: number): void {
      catNav.info(
        `${this.$options.name}:: selectionIndex '${from}' > '${to}' ` + "" //JSON.stringify(to)
      );

      if (
        this.selectionIndex >= 0 &&
        this.selectionIndex < this.produktListe.length
      ) {
        this.selectedProdukt = this.produktListe[this.selectionIndex];
        // console.log(this.selectedProdukt, this.selectedProdukt.fmtPreis());
      }
    },

    $route(to: Route, from: Route): void {
      catNav.info(
        `${this.$options.name}:: Route '${from.fullPath}' > '${to.fullPath}' ` +
          "" //JSON.stringify(to)
      );

      if ("produkt" in to.params) {
        catVue.info("searching ...");
        this.search();
      }
    },
  },
});
